import React from 'react'
import { DayCounter, BusinessDateConventions, Frequency, DateGen } from '../data/Constants'
import { useEffect, useState } from 'react';
import Dropdown from "./Dropdown";
import ValueLabel from "./ValueLabel";
import CashflowsPanel from "./CashflowsPanel"
import USTStaticData from "./USTStaticData"
import LabeledNumericInput from "./LabeledNumericInput";
import BusinessDatePicker from "./BusinessDatePicker";
import helpers from "./helpers";
import { compareAsc, format, parseISO } from 'date-fns'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import TradeCaptureHelper from '../helpers/TradeCaptureHelper'

const BondTermStructurePanel = React.forwardRef ((props, ref) => {


  const [bondName,setBondName] = useState("N/A");
  const [issueDate, setIssueDate] = useState();
  const [maturityDate, setMaturityDate] = useState();
  const [settlementDate, setSettlementDate] = useState();
  const [coupon, setCoupon] = useState(0.0);
  const [tenor, setTenor] = useState();

  const [dayCounter, setDayCounter] = useState();
  const [busDayConv, setBusDayConv] = useState();
  const [frequency, setFrequency] = useState();
  const [dateGen, setDateGen] = useState();
  const [cashflows, setCashflows] = useState();
  const [error, setError] = useState();
  const [valueUpdated, setValueUpdated] = useState(false);

  const [bondYield, setBondYield] = useState(0.0);
  const [price, setPrice] = useState(0.0);
  const [settlementDays, setSettlementDays] = useState(1);
  const [notional, setNotional] = useState(1);
  const [dv01, setDV01] = useState(0.0);
  const [z_spread, setZSpread] = useState(0.0);
  const [modifiedDuration, setModifiedDuration] = useState(0.0);
  const [convexity, setConvexity] = useState(0.0);
  const [ai, setAI] = useState(0.0);
  const [aiDays, setAiDays] = useState(0.0);
  const [fullPrice, setFullPrice] = useState(0.0);
  const [price32, setPrice32] = useState();

  const [ticketDisabled, setTicketDisabled] = useState();
  const [instrument, setInstrument] = useState();

  const handleSetup = (elementName, value) =>
  {
    if ( props.handleSetup !== undefined && props.bond !== undefined )
    {
      props.bond["operation"] = "PRICER_SETUP";
      props.handleSetup(props.bond, elementName, value);
      setCashflows([]);
    }
  };

  const handleChange = (elementName, value) =>
  {
    if ( props.handleSetup !== undefined && props.bond !== undefined )
    {
      props.bond["operation"] = elementName;
      props.handleSetup(props.bond, elementName, value);
    }
  };

  useEffect(() => {

    if ( props.bondPricingError !== undefined )
    {
      setError(props.bondPricingError);
      setCashflows([]);
    } else if ( props.bond !== undefined )
    {

      if ( props.bond.error_code != undefined &&  props.bond.error_code != "0" )
      {
        setError(props.bond.error);
        setCashflows([]);
        return;
      }

      setError("");
      setBondName(props.bond.name);

      var issue_date = props.bond.issue_date;
      var maturity_date = props.bond.maturity_date;
      var settlement_date = props.bond.settlement_date;

      setIssueDate( parseISO( issue_date ) );
      setMaturityDate( parseISO( maturity_date ) );
      setSettlementDate( settlement_date );

      setSettlementDays( props.bond.settlement_days );

      setDayCounter(props.bond.day_counter);
      setBusDayConv(props.bond.bdc);
      setFrequency(props.bond.frequency);
      setDateGen(props.bond.gen_rule);

      setTenor(helpers.int_to_tenor(props.bond.tenor));
      setCoupon(props.bond.coupon);

      setBondYield(parseFloat(props.bond.yield).toFixed(4));
      setPrice(props.bond.price);
      setDV01(props.bond.dv01);
      setNotional(Math.abs(props.bond.notional));
      setModifiedDuration(props.bond.ModifiedDuration);
      setConvexity(props.bond.Convexity);
      setAI(props.bond.AI);
      setAiDays(props.bond['AI(Days)']);
      setFullPrice(props.bond.DirtyPrice);
      setZSpread(props.bond.zSpread|0);
      //var clean_price = props.bond.price;
      //setPrice32(helpers.dec_to_tic(clean_price));

      console.log(props.bond.cashflows);
      if ( props.bond.cashflows != undefined )
        setCashflows(props.bond.cashflows);
      else
        setCashflows([]);

      setValueUpdated(false);

      var instrument = {}
      instrument['symbol'] = props.bond.name
      instrument['cusip'] = props.bond.cusip
      instrument['trade_details']={}
      instrument['trade_details']['expiration_date']=maturity_date

      setInstrument(instrument);
    }

  },  [props]);

  const tradeBookingDone = (response) => {
    props.tradeBookCallback(response)
    setTicketDisabled(false)
 };

  return (

        <Container  style={{backgroundColor:'#21222A', border:'1px solid #404040'}} fluid>
            <Row style={{marginTop:'10px'}}>
              <Col>
                <BusinessDatePicker label='Issue Date' elementName='issue_date' selected_date={issueDate} onValueChange={handleSetup}/>
                <BusinessDatePicker label='Maturity Date' elementName='maturity_date' selected_date={maturityDate} onValueChange={handleSetup}/>
                <Dropdown label='DayCounter' elementName='day_counter' options={DayCounter} selected_value={dayCounter} onValueChange={handleSetup}/>
                <Dropdown label='Bus.Day.Conv.' elementName='bdc' options={BusinessDateConventions} selected_value={busDayConv} onValueChange={handleSetup}/>
                <Dropdown label='Frequency' elementName='frequency' options={Frequency} selected_value={frequency} onValueChange={handleSetup}/>
                <Dropdown label='DateGen' elementName='gen_rule' options={DateGen} selected_value={dateGen} onValueChange={handleSetup}/>
              </Col>
              <Col>
                <ValueLabel label="Tenor" value={tenor} type='label'/>
                <ValueLabel label="Coupon" value={coupon} type='rate'/>
                <LabeledNumericInput label="Price" value={price} elementName="PRICE_TO_YIELD"
                  onChange={handleChange}
                  step={0.00390625}
                  value_updated = {(new_value)=>
                  {
                    setPrice32(helpers.dec_to_tic(new_value));
                    if ( price!==new_value)
                      setValueUpdated(true);
                    else
                      setValueUpdated(false);
                  }}
                  />
                <LabeledNumericInput label="Yield" value={bondYield} elementName="YIELD_TO_PRICE"
                  onChange={handleChange} step={0.01}
                  value_updated = {(new_value)=>
                  {
                      if ( new_value!==bondYield)
                        setValueUpdated(true);
                      else
                        setValueUpdated(false);
                  }}/>
                <LabeledNumericInput label="Settlement Days" value={settlementDays}
                  elementName="settlement_days" onChange={handleSetup} step={1}
                  value_updated = {(new_value)=>
                                    {
                                      if ( new_value!==settlementDays)
                                        setValueUpdated(true);
                                      else
                                        setValueUpdated(false);
                                    }}/>
                <LabeledNumericInput label="Notional" value={notional} elementName="notional"
                  onChange={handleSetup}
                  step={10000}
                  type="notional"
                  value_updated = {(new_value)=>
                  {
                    if ( new_value!==notional)
                      setValueUpdated(true);
                    else
                      setValueUpdated(false);
                  }}/>

                  <div style={{textAlign:'right', padding:'5px', opacity:0.5}}>
                    (change the value and press Enter)
                  </div>

                  <div style={ ( props.userCredentials == undefined || valueUpdated == true ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                  <Row style={{paddingLeft:'100px'}}>
                    <Col  style={{justifyContent:'right', alignItems:'right', display: 'flex'}}>
                      <Button size='sm' onClick={()=> {
                          setTicketDisabled(true)
                          TradeCaptureHelper.submit_trade(props.userCredentials, props.positions,
                              instrument, 'buy', price, parseInt(notional), tradeBookingDone)
                          }}>Buy</Button>
                      </Col>
                    <Col>
                    <Button size="sm" onClick={()=> {
                      setTicketDisabled(true)
                      TradeCaptureHelper.submit_trade(props.userCredentials, props.positions,
                        instrument, 'sell', price, parseInt(notional), tradeBookingDone)
                      }}>Sell</Button>
                      </Col>
                  </Row>
                  </div>

              </Col>
              <Col>
                <div style={ ( valueUpdated == true ) ? { opacity: "0.4"} : {}}>
                <ValueLabel label="Price(32)" value={price32} type='label'/>
                <ValueLabel label="DV01" value={dv01} type='rate'/>
                <ValueLabel label="Mod.Dur." value={modifiedDuration} type='rate'/>
                <ValueLabel label="Convexity" value={convexity} type='rate'/>
                <ValueLabel label="Z-Spread(BPS)" value={z_spread} type='rate'/>
                <ValueLabel label="Settlement Date" value={settlementDate} type='label'/>
                <ValueLabel label="AI" value={ai} type='label'/>
                <ValueLabel label="AI(Days)" value={aiDays} type='rate'/>
                <ValueLabel label="FullPrice" value={fullPrice} type='label'/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {error}
              </Col>
            </Row>
            <Row style={{marginBottom:'10px'}}>
              <Col style={{width:'25%'}}>
                <USTStaticData fedStaticData={props.fedStaticData}/>
              </Col>
              <Col style={{width:'75%'}}>
                <CashflowsPanel cashflows={cashflows}/>
              </Col>
            </Row>
        </Container>

    )
 });



export default BondTermStructurePanel;
