import { useEffect, useState } from 'react';
import React from 'react';

import helpers from './helpers'


import {AgGridColumn, AgGridReact} from 'ag-grid-react';

const { forwardRef, useRef, useImperativeHandle } = React;

const USTStaticData = React.forwardRef ((props, ref) => {

  const staticDataGridRef = React.useRef();

  const [staticData, setStaticData] = useState();

  const date_style =  {'textAlign': 'right', padding:'3px', opacity: '0.7'};

  const staticDataGridColumnDefs = [
    { field: 'data', headerName: 'Data', sortable: true, flex: 2,cellStyle: {'textAlign': 'right', padding:'3px'}, },
    { field: 'value', headerName: 'Value', sortable: true, flex: 2,cellStyle: {'textAlign': 'center', padding:'3px'}, }
  ];

  const staticDataGridOptions = {

    enableRangeSelection: true,
    enableCellTextSelection: true,
    getRowHeight: (params) => 25,
    onGridReady: event => {

    },
}


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

useEffect(() => {

  if ( props.fedStaticData === undefined  )
    return;

  if ( props.fedStaticData.data  === undefined )
    return;

  console.log("Fed staticData " + props.fedStaticData.data);

  var fed_data_out = [];

  for (const [key, value] of Object.entries(props.fedStaticData.data))
  {
    var fed_data = {}
    fed_data['data'] = capitalizeFirstLetter(key);
    fed_data['value'] = value;
    fed_data_out.push(fed_data);

    console.log("|" + key + "|" + value)
  }

  setStaticData(fed_data_out);

  //var cashflows_out = Object.values(cashflows)
  //setCashflows(cashflows_out);

},  [props.fedStaticData]);



   return (
      <div>
        <div className="App-Row">
            <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"40vh", width: "100%", display: "inline-block", margin: "auto", padding:"10px"}}>
            <div style={{marginBottom:'5px'}}>
              Static Data
            </div>
            <AgGridReact rowData={staticData}
                        columnDefs={staticDataGridColumnDefs}
                        gridOptions={staticDataGridOptions}
                          ref={staticDataGridRef}>
            </AgGridReact>
            </div>
          </div>
      </div>
     )
  });

export default USTStaticData;
