import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import helpers from './helpers';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';


const { forwardRef, useRef, useImperativeHandle } = React;

const BondPriceChart = React.forwardRef ((props, ref) => {

  const chartRef = useRef();

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [options, setOptions] = useState({
    labels: [],
    datasets: [],
    maintainAspectRatio: false,
  });

  useEffect(() => {

            var dates = [];
            var prices = [];

            var hist_price_grid = [];

            var bond_price_chart = {}

            if ( props.bondChartData !== undefined && props.bondChartData !== null )
            {
              var hist_price_grid_data = [];

              for (const [key, value] of Object.entries(props.bondChartData))
              {
                if ( key % 2 == 0 )
                {
                  prices.push(value['price']);
                  dates.push(helpers.int_to_date(key));
                }

              }
            }

          const chart_data = {
            labels: dates,
            datasets: [
              {
                data : prices,fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)",
              }]
          };

          const options = {
            plugins: {
              legend: {
                        display: false,
              },
              title: {
                align: 'center',
                position: 'right',
                color:"rgba(75,192,192,1)",
              },
            },
          };

          setOptions(options);
          setData(chart_data);

  }, [props.bondChartData]);



  return (
        <div className="ag-theme-balham-dark">
          <Line options={options} data={data} ref={chartRef} style={{position: 'relative', height:'30vh', width:'80vw'}}/>
        </div>
  );
});

export default BondPriceChart;
