import { SESSION_URL } from './Constants'
import SessionHelper from './SessionHelper'


const TradeCaptureHelper = {

  submit_trade: function(userCredentials, positions, instrument, side, price, quantity, callback)
  {
      var symbol = instrument['symbol'];
      var position = {};

      if (symbol in positions)
      {
        position = positions[symbol]['position_details'];
      } else {
        position['buy_amt'] = 0;
        position['buy_avg_price'] = 0;
        position['sell_amt'] = 0;
        position['sell_avg_price'] = 0;
      }

      if ( side === 'buy' )
      {
        var new_amt = position['buy_amt'] + quantity;
        var new_avg_price = ((position['buy_amt'] * position['buy_avg_price']) + (price * quantity))/new_amt;
        position['buy_amt'] = new_amt;
        position['buy_avg_price'] = new_avg_price;
      } else {
        var new_amt = position['sell_amt'] + quantity;
        var new_avg_price = ((position['sell_amt'] * position['sell_avg_price']) + (price * quantity))/new_amt;
        position['sell_amt'] = new_amt;
        position['sell_avg_price'] = new_avg_price;
      }

      positions[symbol] = position;

      console.log(positions);

      var updated_position = {}
      updated_position['login_data'] = {}
      updated_position['login_data']['email'] = userCredentials['email']
      updated_position['login_data']['jti'] = userCredentials['jti']
      updated_position['position_data'] = {}
      updated_position['position_data']['portal'] = 'BOND_PORTAL'
      updated_position['position_data']['symbol'] = instrument.symbol
      position['instrument'] = instrument
      updated_position['position_data']['position_details'] = position

      SessionHelper.insert_update_position(updated_position, (position_update_response) => { callback(position_update_response); });

      console.log(updated_position);
  },

/*
  compose_option_instrument: function(optionType, ticker, strikeWithGreeks) {

    var instrument = {}

    instrument['contract_multiplier'] = 100
    instrument['underlying_symbol'] = ticker
    instrument['symbol'] = ticker + "_" + optionType[0].toUpperCase() + "_" +
      strikeWithGreeks['Strike'] + "_" +
      strikeWithGreeks['expiration_date'].replaceAll('-','') + '_' + strikeWithGreeks['exercise_type'][0]

    instrument['name'] = ticker + " " + optionType[0].toUpperCase() + optionType.slice(1) + " " +
      strikeWithGreeks['Strike'] + " "+ strikeWithGreeks['expiration_date'] + " " + strikeWithGreeks['exercise_type']

    instrument['trade_details'] = strikeWithGreeks
    instrument['trade_details']['OptionType'] = optionType
    instrument['trade_details']['expiry_date_iso'] = strikeWithGreeks['expiration_date']

    return instrument

  },*/

};

export default TradeCaptureHelper;
