import { useEffect, useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import React from 'react'

import NumberFormat from "react-number-format";

const { forwardRef, useRef, useImperativeHandle } = React;

const ValueLabel = React.forwardRef ((props) => {

  function eLocal(x){

    if (props.type === 'label')
    {
      return x;
    }

    if ( props.type === 'rate')
    {
        let n = Number(x);
        return n.toLocaleString("en-US");
    }

    let n = Number(x);
    let str = '';

    if (x < 0.0 )
    {
      n = n * -1;
      str = "(" + n.toLocaleString("en-US") + ")";
    } else {
      str = n.toLocaleString("en-US");
    }

    return str;
  }

  function get_color()
  {
    if ( props.type != 'rate' &&  props.type != 'label' )
    {
      if ( props.value>=0 )
      {
        return 'rgba(75,192,192)';
      } else {
        return 'rgb(255, 99, 132)';
      }
    }
  }


  return (
    <Container style={{margin:'5px'}}>
      <Row>
        <Col xs={6} style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
          {props.label}
        </Col>
      <Col xs={6} style={{color:get_color()}}>
          {eLocal(props.value)}
      </Col>
      </Row>
      </Container>
    )
 });
export default ValueLabel;
