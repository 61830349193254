import DatePicker from "react-datepicker";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import React from 'react'
import { useEffect, useState } from 'react';
import { compareAsc, format, parseISO } from 'date-fns'

const BusinessDatePicker = React.forwardRef ((props, ref) => {

  const [selectedDate, setSelectedDate] = useState(0);

  useEffect(() =>
  {
    setSelectedDate(props.selected_date);
  }, [props.selected_date]);

  return (
    <Container style={{marginTop:'5px'}}>
      <Row style={{display: 'flex', justifyContent: 'center', width:'100%'}}>

      <Col style={{width:'50%', textAlign:'right', paddingRight:'10px'}}>
        <div className="text-nowrap">
          {props.label}
        </div>
      </Col>

      <Col  style={{width:'50%'}}>
        <DatePicker
        highlightDates={props.instrument_date}
        selected={selectedDate} onChange ={(date)=>
          {
            setSelectedDate(date);
            props.onValueChange(props.elementName, format(date, "yyyy-MM-dd"));
          }
        }
        />
      </Col>

      </Row>
      </Container>
    )
 });

export default BusinessDatePicker;
