import { useCallback, useMemo, useEffect, useState } from 'react';
import React from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import SessionHelper from '../helpers/SessionHelper'

import helpers from './helpers';

import { Container, Row, Col } from 'react-bootstrap/';

const { forwardRef, useRef, useImperativeHandle } = React;

const PositionsPanel = React.forwardRef ((props, ref) => {

  const [positions, setPositions] = useState();
  const [exposure, setExposure] = useState();
  const [logonResponse, setLogonResponse] = useState();
  const positionsGridRef = useRef();
  const exposureGridRef = useRef();

  const amountFormatter = (param) => {

    return parseInt(param).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

   const valueFormatter = (params) => {
      if ( parseInt(params.value) == undefined )
        return '';

      if ( parseInt(params.value) < 0 )
        return '(' + amountFormatter(Math.abs(params.value)) + ')'
      else
        return amountFormatter(params.value);
    }

    const valueFormatterABS = (params) =>
    {
       if ( parseInt(params.value) == undefined )
         return '';

      return amountFormatter(Math.abs(params.value))
    }

  const pnl_renderer = (params) => {
            // put the value in bold;
          if ( params.value == undefined )
            return

            var font_weight = 'normal'
            if (params.data.maturity_range == "Total")
            {
              font_weight = 'bold'
            }

            if ( parseInt(params.value) > 0 )
            {
                return ( <div>
                     <span style={{color: "rgb(152,195,121)", fontWeight: font_weight}}>  {valueFormatter(params)} </span>
                     </div> );
            } else if (parseInt(params.value) < 0) {
                 return ( <div>
                      <span style={{color: "rgb(224,108,118)", fontWeight: font_weight}}> {valueFormatter(params)} </span>
                      </div> );
            }

            return ( <div style={{fontWeight: font_weight}}>
                   <span> {valueFormatter(params)} </span>
                   </div> );
        }

        const value_formatter = (params) => {
                  // put the value in bold;
                if ( params.value == undefined )
                  return

                  var font_weight = 'normal'
                  if (params.data.maturity_range == "Total")
                  {
                    font_weight = 'bold'
                  }


                  return ( <div style={{fontWeight: font_weight}}>
                         <span> {params.value} </span>
                         </div> );
              }

  const [positionsColumnDefs, setPositionsColumnDefs] = useState([
   { headerName: 'Name', field: 'name', filter: 'agTextColumnFilter',  pinned: 'left', width: '150px',sortable: false,flex: 1, cellStyle: {'textAlign': 'center'}},
   { headerName: 'Maturity', field: 'maturity', sortable: true, valueFormatter:
      function (params)
      {
        return helpers.int_to_date(params.value);
      }, flex: 2, cellStyle: {'textAlign': 'center'} },

   { headerName: 'Tenor', field: 'tenor', cellStyle: {'textAlign': 'center'}, sortable: true, valueFormatter: function (params) { return helpers.int_to_tenor(params.value); }, flex: 2 },
   { headerName: 'Price(32)', field: 'price(32)', cellStyle: {'textAlign': 'center'}, valueFormatter: helpers.format_price_32, flex: 2  },
   { headerName: 'Notional', field: 'notional', cellStyle: {'textAlign': 'right'}, flex: 2, valueFormatter:valueFormatter  },
   { headerName: 'P&L', field: 'pnl', flex: 2 , cellStyle: {'textAlign': 'right'}, valueFormatter:valueFormatter, cellRenderer:pnl_renderer  },
   { headerName: 'Yield', field: 'yield', cellStyle: {'textAlign': 'center'}, flex: 2  },
   { headerName: 'DV01', field: 'dv01', flex: 2 ,cellStyle: {'textAlign': 'right'},  valueFormatter:valueFormatterABS  },
   { headerName: 'ModifiedDuration', field: 'ModifiedDuration', flex: 2 , cellStyle: {'textAlign': 'right'}, valueFormatter:valueFormatterABS  },
   { headerName: 'Convexity(100bps)', field: 'Convexity', flex: 2 , cellStyle: {'textAlign': 'right'}, valueFormatter:valueFormatterABS  },
   { headerName: 'CUSIP', field: 'cusip', flex: 2, filter: 'agTextColumnFilter',  },
 ]);

 const [exposureColumnDefs, setExposureColumnDefs] = useState([
  { headerName: 'Maturity Range', field: 'maturity_range', pinned: 'left', width: '100px',sortable: false,flex: 1, cellRenderer: value_formatter },
  { headerName: 'Notional', field: 'notional', cellStyle: {'textAlign': 'right'}, width: '100px', flex: 0, valueFormatter:valueFormatter  },
  { headerName: 'P&L', field: 'pnl', flex: 3 , cellStyle: {'textAlign': 'right'}, valueFormatter:valueFormatter, cellRenderer:pnl_renderer  },
  { headerName: 'DV01', field: 'dv01', flex: 3, cellStyle: {'textAlign': 'right'}, valueFormatter:valueFormatterABS },
  { headerName: 'ModifiedDuration', field: 'ModifiedDuration', flex: 3, cellStyle: {'textAlign': 'right'},valueFormatter:valueFormatterABS },
  { headerName: 'Convexity(100bps)', field: 'Convexity', flex: 3, cellStyle: {'textAlign': 'right'}, valueFormatter:valueFormatterABS  },
  { headerName: 'Order', field: 'order', pinned: 'left', width: '150px',sortable: false,flex: 1,cellStyle: {'textAlign': 'center'},  hide:true},
]);

  const positionsGridOptions = {
     rowSelection: 'single',
     onRowClicked: event => {
        props.bondSelectorCallback(event.data);
     },
     onCellClicked: event => {},
     onColumnResized: event => {},
     onGridReady: event => {
      var defaultSortModel = [
        { colId: 'maturity', sort: 'asc', sortIndex: 0 }
      ];
      positionsGridRef.current.columnApi.applyColumnState({ state: defaultSortModel });
    },

    onRowDataChanged: event => {
      var defaultSortModel = [
        { colId: 'maturity', sort: 'asc', sortIndex: 0 }
      ];
      positionsGridRef.current.columnApi.applyColumnState({ state: defaultSortModel });
    },
     getRowHeight: (params) => 25
 }

  const exposureGridOptions = {
    rowSelection: 'single',
    getRowHeight: (params) => 25,
    onRowDataChanged: event => {
      var defaultSortModel = [
        { colId: 'order', sort: 'asc', sortIndex: 0 }
      ];
      exposureGridRef.current.columnApi.applyColumnState({ state: defaultSortModel });
    },
  }

  const exposureRanges = {
    '0Y-2Y':{start:0, end:732, order:0},
    '2Y-5Y':{start:732, end:1830, order:1},
    '5Y-10Y':{start:1830, end:3660, order:2},
    '10Y-20Y':{start:3660, end:7320, order:3},
    '10Y-30Y':{start:7320, end:10980, order:4},
    'Total':{start:0, end:10980, order:5},
  }


  const get_exposure_range = ( tenor ) =>
  {
    for (const [range, tenors] of Object.entries(exposureRanges))
    {
      if ( tenor >= tenors.start && tenor <= tenors.end)
      {
        return range;
      }
    }

    return 'N/A';
  }

  const reset_exposure = (exposureRanges) =>
  {
    var exposure_keys = [];

    Object.keys(exposureRanges).forEach((exposure_key, i) => {
        var exposure_row = {}
        exposure_row['maturity_range'] = exposure_key
        exposure_row['notional'] = 0
        exposure_row['pnl'] = 0
        exposure_row['dv01'] = 0
        exposure_row['ModifiedDuration'] = 0
        exposure_row['Convexity'] = 0
        exposure_keys.push(exposure_row)
    });

    return exposure_keys;
  }

  useEffect(() => {


    if ( exposureRanges !== undefined )
    {
        var exposure_keys = reset_exposure(exposureRanges)
        setExposure(exposure_keys)
    }

    /*
    var exposure_key_range = Object.keys(exposureRanges).forEach((exposure_key, i) => {
        var exposure_row = {}
        exposure_row['maturity_range'] = exposure_key
        exposure_row['notional'] = 0
        exposure_row['pnl'] = 0
        exposure_row['dv01'] = 0
        exposure_row['ModifiedDuration'] = 0
        exposure_row['Convexity'] = 0
        exposure_keys.push(exposure_row)
    });*/

  },  []);

 useEffect(() => {

   if (props.selectedBond == undefined)
    return;

    positionsGridRef.current.api.forEachNode(node =>
     {
       if (node.data == null )
         return;

      if (node.data.cusip == props.selectedBond['cusip'])
      {
        node.setSelected(true);
        return;
      }
     });

 },  [props.selectedBond]);

 useEffect(() => {

   if ( props.positions !== undefined && props.pricedBonds !== undefined && props.instrumentDataPerCUSIP )
   {
       console.log(props.positions);
       console.log(props.pricedBonds);

       //var exposure_keys = reset_exposure(exposureRanges)
       //setExposure(exposure_keys)

       var positions = []

       var exposure = {};
        Object.keys(exposureRanges).forEach((exposure_key, i) => {
          exposure[exposure_key] = {}
          exposure[exposure_key]['maturity_range'] = exposure_key
          exposure[exposure_key]['notional'] = 0
          exposure[exposure_key]['pnl'] = 0
          exposure[exposure_key]['dv01'] = 0
          exposure[exposure_key]['ModifiedDuration'] = 0
          exposure[exposure_key]['Convexity'] = 0
        });

       for (const [instrument, position] of Object.entries(props.positions))
       {
         var position_details = position['position_details']
         var cusip = position_details['instrument']['cusip']
         var instrument_data = props.instrumentDataPerCUSIP[cusip]
         var priced_bond = props.pricedBonds[cusip]

         if (instrument_data == undefined || priced_bond == undefined)
          continue

         var notional = parseInt(position_details['buy_amt']) - parseInt(position_details['sell_amt'])

         var tenor = parseInt(instrument_data.tenor)

         var position_vwap = ( (position_details['buy_avg_price'] * position_details['buy_amt']) -
            (position_details['sell_avg_price'] * position_details['sell_amt']) )/notional

         instrument_data['notional'] = notional

         instrument_data['dv01']=priced_bond['DV01']/100 * notional/1000000;
         instrument_data['ModifiedDuration']=priced_bond['modified_duration']/100 * notional/1000000;
         instrument_data['Convexity']=priced_bond['convexity']/100 * notional/1000000;

         var clean_price = priced_bond['CleanPrice']/1000000000.0;;
         instrument_data['pnl'] = ((clean_price - position_vwap) * notional)/100.0;

         positions.push(instrument_data)


         var range_description = get_exposure_range(tenor)

         var range_exposure = exposure[range_description]

         if ( range_exposure == undefined )
         {
           range_exposure = {}
           range_exposure['maturity_range'] = range_description
           range_exposure['order'] = exposureRanges[range_description]['order']
           range_exposure['pnl'] = 0
           range_exposure['dv01'] = 0
           range_exposure['ModifiedDuration'] = 0
           range_exposure['Convexity'] = 0
           range_exposure['notional'] = 0
           exposure[range_description] = range_exposure
         }

         exposure[range_description]['pnl'] += instrument_data['pnl']
         exposure[range_description]['dv01'] += instrument_data['dv01']
         exposure[range_description]['ModifiedDuration'] += instrument_data['ModifiedDuration']
         exposure[range_description]['Convexity'] += instrument_data['Convexity']
         exposure[range_description]['notional'] += notional

         exposure['Total']['pnl'] += instrument_data['pnl']
         exposure['Total']['dv01'] += instrument_data['dv01']
         exposure['Total']['ModifiedDuration'] += instrument_data['ModifiedDuration']
         exposure['Total']['Convexity'] += instrument_data['Convexity']
         exposure['Total']['notional'] += notional

       };

       setPositions(positions)

       exposureGridRef.current.api.forEachNode(node =>
        {
          if (node.data == null )
            return;

          var exposure_row = exposure[node.data['maturity_range']]

          if ( exposure_row !== undefined )
            node.setData(exposure_row);
        });
    }

 },  [props.positions, props.pricedBonds]);

 useEffect(() => {

   if (positions == undefined)
    return;

    if ( props.selectedBond != undefined )
    {
      positionsGridRef.current.api.forEachNode(node =>
       {
         if (node.data == null )
           return;

        if (node.data.cusip == props.selectedBond['cusip'])
        {
          node.setSelected(true);
          return;
        }
       });
    }

 },  [positions, props.selectedBond]);



 const onBtnExport = useCallback(() => {
   props.dataExportCallback(positionsGridRef);
 }, []);

  return (
      <Container fluid>
          <Row>
          <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"40vh", width: "70%", display: "inline-block", margin: "auto", paddingTop:"5px"}}>
          <AgGridReact rowData={positions} columnDefs={positionsColumnDefs} gridOptions={positionsGridOptions} ref={positionsGridRef}>
          </AgGridReact>
          </div>
          <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"40vh", width: "30%", display: "inline-block", margin: "auto", paddingTop:"5px"}}>
          <AgGridReact rowData={exposure} columnDefs={exposureColumnDefs} gridOptions={exposureGridOptions} ref={exposureGridRef}>
          </AgGridReact>
          </div>
          </Row>
      </Container>
   );
});

export default PositionsPanel;
