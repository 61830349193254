
const helpers = {

    dateComparator: function(date1, date2) {
    var date1Number = new Date(date1).getTime();
    var date2Number = new Date(date2).getTime();

    if (date1Number == null && date2Number == null) {
      return 0;
    }

    if (date1Number == null) {
      return -1;
    } else if (date2Number == null) {
      return 1;
    }

    return date1Number - date2Number;
  },

  date_js_to_date_mm_dd_yyyy: function(date_in)
{
  return (date_in.getMonth()+1).toString().padStart(2,'0') +
    "/" + date_in.getDate().toString().padStart(2,'0')  + "/" + date_in.getFullYear().toString();
},

  int_to_date_js: function(int_val)
{
  var business_date = parseInt(int_val);
  var year = business_date/10000|0;
  var month = (business_date - (year*10000))/100|0;
  var date = business_date - ((year * 10000) + (month *100))|0;

  return new Date(year, month-1, date);
},

    int_to_date: function(int_val)
    {
      var business_date = parseInt(int_val);
      var year = business_date/10000|0;
      var month = (business_date - (year*10000))/100|0;
      var date = business_date - ((year * 10000) + (month *100))|0;

      return month.toString().padStart(2,'0') + "/" + date.toString().padStart(2,'0')  + "/" + year.toString();
    },
    dec_to_tic: function(dec_price)
    {
      var d_handle = dec_price|0;
      var fraction_1 = dec_price - d_handle;
      var f_handle = fraction_1*32|0;
      var fraction_2 = ((fraction_1-f_handle/32.0)*256.0).toFixed(0);

      if ( fraction_2 === 'NaN' )
        fraction_2 = 0;

      return d_handle.toString() + "-" + f_handle.toString().padStart(2,'0') + fraction_2.toString();
    },
    int_to_tenor: function(int_val)
    {
      if ( int_val < 30 )
      {
        return int_val + 'D';
      } else if (int_val < 365)
      {
        return Math.round(int_val/30)+'M';
      } else {
        return Math.round(int_val/365.4)+'Y';
      }
    },
    format_price_32: function(params)
    {
      var price = params.value;
      var dec_price = helpers.dec_to_tic(price);

      return dec_price;
    }
}

export default helpers;
