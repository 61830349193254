
export const DayCounter = [

  '30/360 (Bond Basis)',
  '30E/360 (Eurobond Basis)',
  '30/360',
  'Actual/360',
  'Actual/365',
  'Actual/Actual',
  'Actual/Actual (ISDA)',
  'Bond Basis',
  'Business252'

]


export const BusinessDateConventions = [

  'Following',
  'Modified Following',
  'Modified Preceding',
  'Preceding',
  'Unadjusted'

]

export const Frequency = [

  'Annual',
  'Bimonthly',
  'Biweekly',
  'Daily',
  'EveryFourthMonth',
  'EveryFourthWeek',
  'Monthly',
  'Once',
  'Quarterly',
  'Semiannual',
  'Weekly'

]

export const DateGen = [
  'Forward',
  'ThirdWednesday',
  'Twentieth',
  'TwentiethIMM',
  'Zero',
  'Backward'
]
