import { useEffect, useState } from 'react';
import React from 'react';

import helpers from './helpers'


import {AgGridColumn, AgGridReact} from 'ag-grid-react';

const { forwardRef, useRef, useImperativeHandle } = React;

const CashflowsPanel = React.forwardRef ((props, ref) => {

  const cashflowsGridRef = React.useRef();

  const [cashflows, setCashflows] = useState();

  const date_style =  {'textAlign': 'right', padding:'3px', opacity: '0.7'};

  const amountFormatter = (param) => {

    return parseInt(param)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

  const cashflowsGridColumnDefs = [
    { field: 'business_date', headerName: 'Date', sortable: true, flex: 2,cellStyle: {'textAlign': 'center', padding:'3px'}, },
      { field: 'accrual_start_date',  headerName: 'Start',   cellStyle: date_style, sortable: false, flex: 2 },
      { field: 'end_date',  headerName: 'End', cellStyle: date_style,  sortable: false, flex: 2 },
      { field: 'amount',   headerName: 'Amount', flex: 2, cellStyle: {'textAlign': 'right', padding:'3px'}, valueFormatter:function (params) {
        if (params.value !== undefined )
        {
          if ( (props.termStructure!= undefined && props.termStructure.pay_or_recieve==='Payer') )
            return '(' + amountFormatter(params.value) + ')';
          else
            return amountFormatter(params.value);
        }
      }
    }
  ];

  const cashflowsGridOptions = {

    getRowHeight: (params) => 25,
    onGridReady: event => {

      var defaultSortModel = [
        { colId: 'business_date', sort: 'asc', sortIndex: 0 }
      ];

      cashflowsGridRef.current.columnApi.applyColumnState({ state: defaultSortModel });
    },
    //getRowHeight: (params) => 25
}

useEffect(() => {

  if ( props.cashflows === undefined )
    return;

  var cashflows = {};

  for (const [date, cashflow] of Object.entries(props.cashflows))
  {
    if ( cashflows[date] === undefined )
    {
      cashflows[date] = {};
      cashflows[date]['business_date'] = date;
      cashflows[date]['accrual_start_date'] = cashflow.accrual_start_date;
      cashflows[date]['end_date'] = cashflow.end_date;
      cashflows[date]['amount'] = cashflow.amount;
    }
  }


  var cashflows_out = Object.values(cashflows)
  setCashflows(cashflows_out);

},  [props.cashflows]);



   return (
      <div>
        <div className="App-Row">
            <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"40vh", width: "100%", display: "inline-block", margin: "auto", padding:"10px"}}>
            <div style={{marginBottom:'5px'}}>
              Cashflows
            </div>
            <AgGridReact rowData={cashflows}
                        columnDefs={cashflowsGridColumnDefs}
                        gridOptions={cashflowsGridOptions}
                          ref={cashflowsGridRef}>
            </AgGridReact>
            </div>
          </div>
      </div>
     )
  });

export default CashflowsPanel;
